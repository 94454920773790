import React, {useRef, useEffect} from 'react'

import Img from "../image"
import Headline from "../GeneralHeadline/GeneralHeadline"

import "./InfoCard.scss"

const InfoCard = ({data, gsap, ScrollTrigger}) => {
    const headline = data.mainHead.split("/");    
    const subhead = data.subHead.split("/");

    gsap.registerPlugin(ScrollTrigger)

    let imgAnimation = useRef()
    let textAnimation = useRef([])
    
    textAnimation.current = []

    const addToReveal = (el) => {
        if(el && !textAnimation.current.includes(el)) {
            textAnimation.current.push(el)
        }
    }
    
    useEffect(() => {
        gsap.to([imgAnimation], {
            duration: 1,
            y: "-6vw",
            scrollTrigger: {
              trigger: imgAnimation,
              toggleActions: "restart pause reverse pause",
              scrub: 1,
            },
        })
        gsap.from([textAnimation.current], {
            delay: .2,
            duration: .4,
            opacity: 0,
            stagger:  {
                amount: .4
            },
            scrollTrigger: {
                trigger: [textAnimation.current],
            }
        })
    })

    // const textFormater = (contentId) => {
    //     switch(contentID) {
    //         case "creation":
    //         if(textData[0]) {

    //         }
    //     }
    // }

    const textData = data.content.map(block => block.text.split(" "))

    console.log(textData[0][0])
    
    return (
        <>
            <div className={`info-container info-${data.id}`}>
                <div className="info-head-container">
                    <div ref={el => imgAnimation = el} className="info-img-wrapper">
                        <Img image={data.img} />
                    </div>
                    <div className="info-headline-wrapper">
                        {headline.map((line, i) => (
                                <Headline key={i} line={line} id="main" />
                        ))}
                    </div>
                </div>
                <div className="number-container">
                    <p>{data.number}</p>
                </div>
                <div className="info-content-container">
                    <div className="info-subhead-container">
                        {subhead.map((line, i) => (
                                    <Headline key={i} line={line} id="sub" />
                                ))}
                    </div>
                    <div className="info-text-container">
                        {data.content.map(text => (
                            <div ref={addToReveal} key={text.id} className="info-text-wrapper"><p>{text.text}</p></div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoCard
