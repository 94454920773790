import React, {useRef, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SEO from "../components/seo"

import Layout from "../components/layout"
import TextHeadline from "../components/GeneralHeadline/TextHeadline"
import Card from "../components/GeneralCard/InfoCard"

const data = [
    {id: 1, mainHead: "cre/ation", subHead: "...needs a/purpose!", content: [{id: 1, text: `In commercial or corporate field your idea most likely will be effective if you know who you create it for and why… Concept, idea, campaign or a public image is only done properly if the vision where we are heading to is clear.`}, {id: 2, text: `With an international background and over 10 years experience in the field we are happy to investigate on your idea, help you bring all the puzzles together and get a big picture of the result.`}], number: 1, img: "creation"},
    {id: 2, mainHead: "execu/tion", subHead: "...needs a/purpose!", content: [{id: 1, text: "We deliver wire frames, plans, sketches but also elaborate low and high fidelity designs, UX & UI designs, webpages and applications for digital audience."}, {id: 2, text: "Every single step is more efficient if considering your customers perspective. We analyse your market and target groups as well as picture your customers journey in order to create content with effect and result."}], number: 2, site: "concept", img: "execution"},
    {id: 3, mainHead: "distribu/tion", subHead: "...needs a/purpose!", content: [{id: 1, text: "Digital appearance is a solid base that must be granted first before spreading the word. If we have done all steps until here together - it means that online marketing, optimisation and social media campaigns are ready to be launched."}, {id: 2, text: "Our online marketing experts will work remotely to push your content and establish a communication strategy to maintain your customer relations."}], number: 3, site: "concept", img: "distribution"},
]

const conceptHeadline = [
    {id: 1, title: "we are led"},
    {id: 2, title: "by becoming"},
    {id: 3, title: "part of"},
    {id: 4, title: "your team so"},
    {id: 5, title: "together we"},
    {id: 6, title: "make your vision"},
    {id: 7, title: "clear and aim driven"},
    {id: 8, title: "to success"}
]



const Concept = () => {
    let revealRef = useRef([])

    revealRef.current = []

    const addToReveal = (el) => {
        if(el && !revealRef.current.includes(el)) {
            revealRef.current.push(el)
        }
    }

    useEffect(() => {
        gsap.to(revealRef.current, {
            y: "0%",
            duration:0.4,
            delay: .2,
            ease: "Power3.easeInOut",
            stagger:  {
                amount: .6
            }
        })
    }, [])

    return (
        <Layout site="layout-container-concept">
                <SEO title="Concept" />
                <div className="concept-text-headline-container">
                    {conceptHeadline.map(line => (
                            <TextHeadline key={line.id} text={line} site="concept" refId={addToReveal} />
                    ))}
                </div>
                <div className="info-card-container">
                    {data.map(card => (
                        <Card key={card.id} data={card} gsap={gsap} ScrollTrigger={ScrollTrigger} />
                    ))}
                </div>
        </Layout>
    )
}

export default Concept
