import React from 'react'

import "./TextHeadline.scss"

const TextHeadline = ({text, site, refId}) => {    
    const lineHandeler = (siteType) => {
        switch(siteType) {
            case "concept":
                if(text.id === 4 || text.id === 5 || text.id === 6) {
                    return <h2><span>{text.title.split(" ").slice(0, -1).join(" ")}</span> <span>{text.title.split(" ").slice(-1).join(" ")}</span></h2>
                } else {
                    return <h2>{text.title}</h2>
                }
             default:
                    return <h2>{text.title}</h2>
        }
    }

    return (
        <div className="text-overflow">
            <div ref={refId} className={`text-head-wrapper text-head-wrapper-${text.id}`}>
                {lineHandeler(site)}
            </div>
        </div>
    )
}

export default TextHeadline